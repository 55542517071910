<template>
    <div class="p-2">
        <div class="w-100 mt-2 text-center" style="position: relative">
            <b-img :src="imageUrl" class="w-100 h-100" fluid></b-img>
            <div class="signalDot" style="position: absolute; top: 20%; left: 15%"></div>
            <div class="signalDot" style="position: absolute; top: 34%; left: 1.2%"></div>
            <div class="signalDot" style="position: absolute; top: 34%; left: 7%"></div>
            <div class="signalDot" style="position: absolute; top: 34%; left: 12.5%"></div>
            <div class="signalDot" style="position: absolute; top: 34%; left: 18.3%"></div>
            <div class="signalDot" style="position: absolute; top: 22.2%; left: 24%"></div>
            <div class="signalDot" style="position: absolute; top: 20%; left: 33.7%"></div>
            <div class="signalDot" style="position: absolute; top: 22.2%; left: 40%"></div>
            <div class="signalDot" style="position: absolute; top: 20%; left: 59.6%"></div>
            <div class="signalDot" style="position: absolute; top: 20%; left: 62.2%"></div>
            <div class="signalDot" style="position: absolute; top: 20%; left: 64.2%"></div>
            <div class="signalDot" style="position: absolute; top: 20%; left: 65.1%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 9%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 20%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 30.4%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 40.3%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 50%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 61.5%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 73%"></div>
            <div class="signalDot" style="position: absolute; top: 60%; left: 85.1%"></div>
            <div class="signalDot" style="position: absolute; top: 72.6%; left: 94%"></div>
            <div class="signalDot" style="position: absolute; top: 77.4%; left: 94%"></div>
            <div class="signalDot" style="position: absolute; top: 82%; left: 94%"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SystemNetworkTopology",
    setup() {
        const imageUrl = require("@/assets/images/jsf35/system-network-topology.png");
        return {
            imageUrl,
        };
    },
};
</script>

<style scoped>
.signalDot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: green;
}
</style>